import { CarShopLabels, ShopCar } from './car-shop-types'
import formatWithCurrencySymbol from '../../utilities/general/format-with-currency-symbol'
import { getLocaleString } from '../general/locale'
import { getLowestPrice } from './get-lowest-price'
import { LocaleObject } from '../general/types'

interface GetPriceStringPartsParam {
    carShopLabels: CarShopLabels
    locale: LocaleObject
    offerTypeName: string
    shopCar: ShopCar
}

export default function getPriceStringParts({
    carShopLabels,
    locale,
    offerTypeName,
    shopCar,
}: GetPriceStringPartsParam): string[] {
    const rawPrice = getLowestPrice(shopCar, offerTypeName)
    const price = formatWithCurrencySymbol(rawPrice, getLocaleString(locale), {
        hardcodeCurrency: 'EUR',
        displayAsFloat: false,
    })

    const taxAbbr =
        offerTypeName === 'CarShop_ShopOfferPrivateLease'
            ? carShopLabels.includingTaxAbbreviation
            : carShopLabels.excludingTaxAbbreviation

    if (offerTypeName === 'CarShop_ShopOfferPurchase') {
        return [price, taxAbbr]
    }

    return [price, taxAbbr, carShopLabels.perMonthAbbreviation]
}
