import { ShopOfferType } from './car-shop-types'

interface ShopOfferTypeWithOrderPriority extends ShopOfferType {
    orderPriority: number
}

export default function getShopOfferTypes(types: string[]): ShopOfferType[] {
    const includedTypes = types.reduce((availableTypes: ShopOfferTypeWithOrderPriority[], type: string) => {
        if (type === 'fullOperationalLease') {
            return [...availableTypes, { name: 'ShopOfferFOL', url: '', orderPriority: 0 }]
        } else if (type === 'privateLease') {
            return [...availableTypes, { name: 'ShopOfferPrivateLease', url: 'private-lease', orderPriority: 1 }]
        } else if (type === 'ready2Share') {
            return [...availableTypes, { name: 'ShopOfferReady2Share', url: 'ready-2-share', orderPriority: 2 }]
        } else if (type === 'purchase') {
            return [...availableTypes, { name: 'ShopOfferPurchase', url: 'buy', orderPriority: 3 }]
        }

        return availableTypes
    }, [])

    // Ensure that there will also be a naked occasions url if FOL is not included.
    return includedTypes.some(({ url }) => !url)
        ? includedTypes.map(({ name, url }) => ({ name, url }))
        : includedTypes
              .sort((typeA, typeB) => typeA.orderPriority - typeB.orderPriority)
              .map(({ name, url }, idx) => (idx === 0 ? { name, url: '' } : { name, url }))
}
