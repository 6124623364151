import { CarShopLabels, ShopCar } from './car-shop-types'
import { getLocaleString } from '../general/locale'
import { LocaleObject } from '../general/types'

interface GetAvailabilityStringParam {
    carShopLabels: CarShopLabels
    locale: LocaleObject
    shopCar: ShopCar
}

export default function getAvailabilityString({ carShopLabels, locale, shopCar }: GetAvailabilityStringParam): string {
    const today = new Date()
    const availableDate = new Date(shopCar.availabilityDate)
    const differenceInDays = (availableDate.getTime() - today.getTime()) / (1000 * 3600 * 24)

    if (shopCar.contractTakeover && differenceInDays < 15) {
        return carShopLabels.availabilityStateAvailableContractTakeovers
    } else if (differenceInDays < 1) {
        return carShopLabels.availabilityStateNowAvailable
    }

    const dateTimeOptions = { year: 'numeric', month: 'short', day: 'numeric' }

    return carShopLabels.availabilityStateAtXDate.replace(
        /\{\{\s*date\s*\}\}/,
        Intl.DateTimeFormat(getLocaleString(locale), dateTimeOptions).format(availableDate)
    )
}
