import { CarShopFiltersInterface } from './filter-shop-cars'
import queryString from 'query-string'

export function makeCarQueryString(filters: CarShopFiltersInterface): string {
    return queryString.stringify(filters)
}

export function makeOverviewQueryString(queryStr: string): string {
    return queryStr.replace(/offerType=\w+&/g, '')
}
