import React from 'react'
import styled from 'styled-components'

const ChargingIcon = (): JSX.Element => (
    <SVG version="1.1" viewBox="0 0 14 23" xmlns="http://www.w3.org/2000/svg">
        <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
            <g fill="currentColor" transform="translate(-1038.000000, -248.000000)">
                <polygon points="1045.6872 248.5 1038.6872 261.5 1044.6872 261.5 1044.6872 270.5 1051.6872 257.5 1045.6872 257.5"></polygon>
            </g>
        </g>
    </SVG>
)

export default ChargingIcon

const SVG = styled.svg`
    height: 100%;
    width: auto;
`
