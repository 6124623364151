import CarShopBackground from '../car-shop/car-shop-background'
import CarShopCard from '../car-shop/car-shop-card'
import CarShopFilters from '../car-shop/car-shop-filters'
import CarShopIntro, { CarShopIntroImage } from '../car-shop/car-shop-intro'
import { CarShopLabels, CarShopType, ShopCar } from '../../utilities/car-shop/car-shop-types'
import CarShopNoneFound from '../car-shop/car-shop-none-found'
import Columns from '../ui/columns'
import { CraftEntry } from '../../utilities/general/types'
import filterShopCars, { CarShopFiltersInterface } from '../../utilities/car-shop/filter-shop-cars'
import Footer from '../core/footer'
import getShopOfferTypes from '../../utilities/car-shop/get-shop-offer-types'
import GlobalStyles from '../core/global-styles'
import Header from '../core/header'
import OuterWrapper from '../core/outer-wrapper'
import { PageContext } from './default-page'
import PageWrapper from '../ui/page-wrapper'
import preparePageData from '../../utilities/craft-data/prepare-page-data'
import React, { useState } from 'react'
import SEO from '../core/seo'
import styled from 'styled-components'

interface CarShopOverviewContext extends PageContext {
    carShopLabels: CarShopLabels
    carShopOverviewData: CraftEntry
    carShopType: CarShopType
    shopCars: ShopCar[]
}

const CarShopOverviewPage = ({
    pageContext,
    location,
}: {
    pageContext: CarShopOverviewContext
    location: any
}): JSX.Element => {
    const wasRedirected = location.state && location.state.from404Redirect
    const { carShopLabels, carShopOverviewData, carShopType, currentLocale, shopCars } = pageContext
    const { footerProps, headerProps, seoProps } = preparePageData({
        ...pageContext,
        currentEntry: carShopOverviewData,
    })

    const [filteredShopCars, setFilteredShopCars] = useState([])
    const [currentFilters, setCurrentFilters] = useState({ color: '', model: '', offerType: '', sort: '' })
    const [shopOfferType, setShopOfferType] = useState('')

    const handleFiltersChange = (filters: CarShopFiltersInterface) => {
        setFilteredShopCars(filterShopCars(shopCars, filters) as never[])
        setCurrentFilters(filters)
    }

    return (
        <OuterWrapper currentLocale={currentLocale}>
            <SEO {...seoProps} />
            <GlobalStyles />
            <PageWrapper>
                <Header {...headerProps} />
                <Main>
                    <CarShopBackground>
                        <Columns>
                            <CarShopIntro
                                introText={
                                    wasRedirected
                                        ? carShopLabels.redirectedFromUnknownIdBanner
                                        : carShopOverviewData.carShopOverviewIntroductoryText
                                }
                                introImages={[
                                    carShopOverviewData.carShopOverviewIntroductoryImage1 as CarShopIntroImage,
                                    carShopOverviewData.carShopOverviewIntroductoryImage2 as CarShopIntroImage,
                                ]}
                            />
                        </Columns>
                        <Columns>
                            <CarShopFilters
                                availableShopOfferTypes={getShopOfferTypes(carShopOverviewData.availableShopOfferTypes)}
                                carShopLabels={carShopLabels}
                                carShopType={carShopType}
                                locale={currentLocale}
                                location={location}
                                onFilterChange={(filters: CarShopFiltersInterface) => handleFiltersChange(filters)}
                                onOfferTypeChange={(offerTypeName: string) => setShopOfferType(offerTypeName)}
                                shopCars={shopCars}
                            />
                        </Columns>
                        <Columns asList={true} layoutOverride="carGrid">
                            {filteredShopCars.map((shopCar: ShopCar) => {
                                return (
                                    <CarShopCard
                                        key={shopCar.id}
                                        carShopLabels={carShopLabels}
                                        carShopType={carShopType}
                                        currentFilters={currentFilters}
                                        locale={currentLocale}
                                        selectedOfferType={shopOfferType}
                                        shopCar={shopCar}
                                    />
                                )
                            })}
                        </Columns>
                        {filteredShopCars.length === 0 && (
                            <Columns>
                                <CarShopNoneFound text={carShopLabels.noCarsMatchTheseFiltersText} />
                            </Columns>
                        )}
                    </CarShopBackground>
                </Main>
                <Footer {...footerProps} />
            </PageWrapper>
        </OuterWrapper>
    )
}

export default CarShopOverviewPage

const Main = styled.main`
    flex-grow: 1;
    display: flex;
`
