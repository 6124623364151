import { CarShopFiltersInterface } from '../../utilities/car-shop/filter-shop-cars'
import { CarShopLabels, CarShopType, ShopCar } from '../../utilities/car-shop/car-shop-types'
import ChargingIcon from '../ui/charging-icon'
import getAvailabilityString from '../../utilities/car-shop/get-availability-string'
import getCarShopRootPath from '../../utilities/car-shop/get-car-shop-root-path'
import getPriceStringParts from '../../utilities/car-shop/get-price-string-parts'
import { Link } from 'gatsby'
import { LocaleObject } from '../../utilities/general/types'
import { makeCarQueryString } from '../../utilities/car-shop/filters-query-string'
import { makeTitleCase } from '../../utilities/general/labels'
import React from 'react'
import styled, { css } from 'styled-components'

// eslint-disable-next-line
// @ts-ignore
import imageFallback from '../../assets/tesla-logo.png'

export interface CarShopCardProps {
    carShopLabels: CarShopLabels
    carShopType: CarShopType
    currentFilters: CarShopFiltersInterface
    locale: LocaleObject
    selectedOfferType: string
    shopCar: ShopCar
}

const CarShopCard = ({
    carShopLabels,
    carShopType,
    currentFilters,
    locale,
    shopCar,
    selectedOfferType,
}: CarShopCardProps): JSX.Element => {
    const carShopRootPath = getCarShopRootPath({ carShopType, locale })
    const queryString = currentFilters ? `?${makeCarQueryString(currentFilters)}` : ''
    const url = `${carShopRootPath}/${shopCar.id}${queryString}`
    const [price, ...priceText] = getPriceStringParts({
        carShopLabels,
        locale,
        offerTypeName: selectedOfferType,
        shopCar,
    })

    return (
        <Card>
            <Link to={url}>
                <ImageContainer>
                    {shopCar.superCharging === 'transferable' && shopCar.availability === 'AVAILABLE' && (
                        <ImageAddition>
                            <ChargingIcon />
                            free supercharging
                        </ImageAddition>
                    )}
                    {shopCar.availability !== 'AVAILABLE' && (
                        <ImageOverlay>
                            {shopCar.availability === 'RESERVED'
                                ? carShopLabels.availabilityStateReserved
                                : carShopLabels.availabilityStateNotAvailable}
                        </ImageOverlay>
                    )}
                    <ImageBox>
                        <Image
                            src={shopCar.previewImageUrl || imageFallback}
                            alt=""
                            loading="lazy"
                            useEnlargedVersion={
                                shopCar.model.name.toLowerCase().endsWith('x') && !!shopCar.previewImageUrl
                            }
                            usesFallbackImage={!shopCar.previewImageUrl}
                        />
                    </ImageBox>
                    {shopCar.availability === 'AVAILABLE' && (
                        <AdditionalInfoContainer>
                            <CarAvailability>
                                {getAvailabilityString({ carShopLabels, locale, shopCar })}
                            </CarAvailability>
                            {!selectedOfferType.includes('PrivateLease') && shopCar.banner && (
                                <OptionalBanner>{shopCar.banner.label}</OptionalBanner>
                            )}
                        </AdditionalInfoContainer>
                    )}
                </ImageContainer>
                <TextContainer>
                    <CarDetails>
                        <CarTitle>{makeTitleCase(`${shopCar.brand.name} ${shopCar.model.name}`)}</CarTitle>
                        <CarSubtitle>{shopCar.edition.name}</CarSubtitle>
                        <CarInformation>{shopCar.licensePlate}</CarInformation>
                    </CarDetails>
                    {selectedOfferType !== 'CarShop_ShopOfferReady2Share' && price.indexOf('NaN') === -1 ? (
                        <CarPrice>
                            <strong>{price}</strong>
                            <small>{priceText.length > 1 ? `${priceText[0]} - ` : priceText[0]}</small>
                            {priceText.length > 1 ? priceText[1] : ''}
                        </CarPrice>
                    ) : (
                        <CarPrice />
                    )}
                </TextContainer>
            </Link>
        </Card>
    )
}

export default CarShopCard

const carShopCardBlue = '#60b4c2'

const Card = styled.li`
    a {
        height: 100%;
        width: 100%;
        background-color: var(--color-white);
        color: inherit;
        box-shadow: 0 0.0625rem 0.5rem 0 rgba(0, 0, 0, 0.08);
        transition: all 0.25s cubic-bezier(0.02, 0.01, 0.47, 1);
        display: flex;
        flex-direction: column;

        &:focus,
        &:hover {
            transform: scale(1.015);
        }
    }
`

const ImageContainer = styled.div`
    flex-shrink: 0;
    position: relative;
    padding: 1rem 0;
`

const ImageAddition = styled.p`
    position: absolute;
    top: 0.5rem;
    left: 0.5rem;
    color: rgba(33, 33, 33, 0.5);
    font-size: var(--font-size-extra-small);
    margin: 0;
    display: flex;
    align-items: center;

    svg {
        height: 1.25rem;
        width: auto;
        color: #ef473a;
        margin-right: 0.375rem;
    }
`

const ImageOverlay = styled.p`
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(96, 180, 194, 0.8);
    color: var(--color-white);
    font-weight: 500;
    text-shadow: 0 0 0.3125rem ${carShopCardBlue};
    margin: 0;
    display: flex;
    align-items: center;
    justify-content: center;
`

const ImageBox = styled.div`
    height: calc((100vw - 7rem) / 3 * 0.55);
    min-height: 13rem;
    max-height: 15.25rem;
    width: 100%;
    overflow: hidden;
`

const Image = styled.img<{ useEnlargedVersion: boolean; usesFallbackImage: boolean }>`
    display: block;
    height: 100%;
    width: 100%;
    object-fit: cover;

    ${props =>
        props.useEnlargedVersion &&
        css`
            height: calc(100% + 6rem);
            width: calc(100% + 6rem);
            margin: -3rem;
        `}

    ${props =>
        props.usesFallbackImage &&
        css`
            height: 90%;
            width: 100%;
            object-fit: contain;
        `}
`

const AdditionalInfoContainer = styled.div`
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

const CarAvailability = styled.p`
    display: inline-block;
    height: 1.5rem;
    background-image: linear-gradient(90deg, rgba(68, 68, 68, 0.8), #444, #444);
    color: var(--color-white);
    font-size: var(--font-size-extra-small);
    line-height: 1.5rem;
    padding: 0 0.5rem;
    border-top-left-radius: 0.625rem;
    border-bottom-left-radius: 0.625rem;
    margin: 0;
`

const OptionalBanner = styled.p`
    min-height: 2.625rem;
    width: 100%;
    background-color: ${carShopCardBlue};
    color: var(--color-white);
    font-size: var(--font-size-extra-small);
    line-height: 1.2;
    padding: 0.375rem 0.625rem;
    margin: 0;
    display: flex;
    justify-content: center;
    align-items: center;
`

const TextContainer = styled.div`
    flex-grow: 9;
    padding: 0.75rem 1rem;
    display: flex;

    > :first-child {
        width: calc(62% - 0.75rem);
        margin-right: 0.75rem;
    }

    > :last-child {
        width: 38%;
    }
`

const CarDetails = styled.div`
    display: flex;
    flex-direction: column;
`

const CarTitle = styled.h2`
    color: var(--color-black-light);
    font-size: 1.25rem;
    margin: 0;
`

const CarSubtitle = styled.p`
    color: var(--color-black-light);
    font-family: var(--font-headings);
    font-size: var(--font-size-small);
    margin: 0 0 0.75rem;
`

const CarInformation = styled.p`
    color: var(--color-black-light);
    font-size: var(--font-size-extra-small);
    margin: auto 0 0;
`

const CarPrice = styled.p`
    font-family: var(--font-headings);
    font-size: var(--font-size-small);
    text-align: right;
    margin: 0;

    strong {
        display: block;
        color: var(--color-black-light);
        font-size: 1.25rem;
        font-weight: var(--font-weight-bold);
        line-height: var(--line-height-headings);
    }

    small {
        font-size: 0.625rem;
    }
`
