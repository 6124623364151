import { CarShopLabels, ShopOfferType } from './car-shop-types'

export default function getPrettyOfferTypeName(
    carShopLabels: CarShopLabels,
    offerType: ShopOfferType | string
): string {
    const offerTypeName = typeof offerType === 'string' ? offerType.replace('CarShop_', '') : offerType.name

    if (offerTypeName === 'ShopOfferFOL') {
        return carShopLabels.offerTypeFolName
    } else if (offerTypeName === 'ShopOfferPrivateLease') {
        return carShopLabels.offerTypePrivateLeaseName
    } else if (offerTypeName === 'ShopOfferReady2Share') {
        return carShopLabels.offerTypeReady2ShareName
    } else if (offerTypeName === 'ShopOfferPurchase') {
        return carShopLabels.offerTypePurchaseName
    }

    return ''
}
