import { ShopCar, ShopOfferFOL, ShopOfferPrivateLease, ShopOfferPurchase, ShopOfferReady2Share } from './car-shop-types'

export function getLowestPrice(shopCar: ShopCar, offerTypeName: string): number {
    const offer = shopCar.offers.find(({ __typename }: { __typename: string }) => __typename === offerTypeName)

    if (typeof offer === 'undefined') {
        return NaN
    }

    if (offerTypeName === 'CarShop_ShopOfferPrivateLease') {
        const prices = (offer as ShopOfferPrivateLease).prices || []
        return prices.map(({ priceIncVAT }) => priceIncVAT).sort((a, b) => a - b)[0]
    } else if (offerTypeName === 'CarShop_ShopOfferPurchase') {
        return (offer as ShopOfferPurchase).priceExVAT || NaN
    } else if (offerTypeName === 'CarShop_ShopOfferReady2Share') {
        const prices = (offer as ShopOfferReady2Share).prices

        return prices.reduce((lowestPrice, { businessPrice, privatePrice }) => {
            const price = businessPrice + privatePrice
            return price < lowestPrice ? price : lowestPrice
        }, Infinity)
    }

    const prices = (offer as ShopOfferFOL).prices || [{ priceExVAT: NaN }]
    return prices.map(({ priceExVAT }) => priceExVAT).sort((a, b) => a - b)[0]
}
