import { CarShopType } from './car-shop-types'
import { LocaleObject } from '../general/types'
import { withLocalePrefix } from '../general/locale'

interface GetCarShopRootPathParam {
    carShopType: CarShopType
    locale: LocaleObject
}

export default function getCarShopRootPath({ carShopType, locale }: GetCarShopRootPathParam): string {
    const localePrefix = withLocalePrefix() ? `/${locale.language}` : ''
    const carShopPath = carShopType === CarShopType.OCCASIONS ? 'occasions' : 'inventories'
    return `${localePrefix}/${carShopPath}`
}
